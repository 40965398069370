import {
  Typography,
  Avatar,
  Box,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import React from "react";
import { useTheme } from "@emotion/react";
import { useRetrieve } from "../hooks/useRetrieve";

const NewlyJoinedUsersWidgets = () => {

  let { documents : users } = useRetrieve('users_accnt');

  const joins = users;
  /*
  trying to convert date but that doesn't work
  function transformDate() {
    users.forEach(element => {
      const d = new Date(element.created_at["seconds"] * 1000 + element.created_at["nanoseconds"] / 1000000);
      element.created_at = d.getDate() + "/" + Number(d.getMonth() + 1) + "/" + d.getFullYear();
      //console.log(element.created_at);
    });
    return users;
  }*/
  // convert date object :
  // new Date(meeting.created_at["seconds"] * 1000 + meeting.created_at["nanoseconds"] / 1000000)
  // console.log(joins);
  // <Route path="/tmc" element={<Home isLoaded={isLoaded} setIsLoaded={setIsLoaded} />} />

  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography component="h2" marginBottom={3} variant="h4">
        {"Quelques Nouveaux Utilisateurs"}
      </Typography>
      {joins.slice(0,5).map((meeting) => (
        <Card key={meeting.id} sx={{ mb: 2 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={`${meeting.forename} avatar`}
              src={meeting.profilePic}
              sx={{ mr: 2 }}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="div" variant="h6">
                {meeting.forename}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="div">
                {
                  new Date(meeting.created_at["seconds"] * 1000 + meeting.created_at["nanoseconds"] / 1000000).getDay() +
                  "/" + Number(new Date(meeting.created_at["seconds"] * 1000 + meeting.created_at["nanoseconds"] / 1000000).getMonth() + 1)
                  + "/" + new Date(meeting.created_at["seconds"] * 1000 + meeting.created_at["nanoseconds"] / 1000000).getFullYear()
                }
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
      <Link to={`/${process.env.PUBLIC_URL}/users`}>
        <Card sx={{ bgcolor: theme.palette.divider, mt: 1 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ bgcolor: "background.paper", mr: 2 }}>
              <ListIcon />
            </Avatar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="div" variant="h6">
                {"Consulter les utilisateurs"}
              </Typography>
            </Box>
            <Button aria-label="Check users">
              <ChevronRightIcon />
            </Button>
          </CardContent>
        </Card>
      </Link>
    </React.Fragment>
  );
};

export { NewlyJoinedUsersWidgets };
