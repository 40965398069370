import React, { useState } from "react";
import Empty from "./Empty";
import {
  Avatar,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingTableView from "./LoadingView";
import { randomColor } from "../utils/randColorCode";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

const headCells = [
  {
    id: "customer",
    align: "left",
    label: "Client",
  },
  {
    id: "payment",
    align: "center",
    label: "Paiement",
  },
  {
    id: "departureAddress",
    align: "center",
    label: "Adresse de depart",
  },
  {
    id: "arrivalAddress",
    align: "center",
    label: "Adresse d'arrivee",
  },
  {
    id: "validatedAt",
    align: "center",
    label: "Date de validation",
  },
];

function EnhancedTableHead({ rowCount }) {
  return (
    <TableHead>
      <TableRow sx={{ "& th": { border: 0 } }}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} sx={{ py: 0 }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const HistoryRow = ({ index, processing, history }) => {
  const updated_at = () => {
    if (
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getDay() === new Date().getDay() &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Aujourd'hui";
    } else if (
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 1 &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Hier";
    } else if (
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 2 &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Avant-hier";
    } else if (
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 3 &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Il y a 3 jours";
    } else {
      return new Date(
        history.updated_at.seconds * 1000 +
          history.updated_at.nanoseconds / 1000000
      )
        .toLocaleString("fr-CM")
        .toString();
    }
  };

  return (
    <TableRow
      tabIndex={-1}
      key={history.id}
      sx={{
        bgcolor: "#DFF3ED",
        "& td": {
          border: 0,
        },
      }}
    >
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {history.userBaseData.profilePic === "" ? (
            <Avatar
              sx={{
                mr: 3,
                width: 56,
                height: 56,
                backgroundColor: randomColor(),
                fontSize: 30,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {history.userBaseData.name != null &&
              history.userBaseData.name !== ""
                ? history.userBaseData.name.toString().charAt(0).toUpperCase()
                : history.userBaseData.forename
                    .toString()
                    .charAt(0)
                    .toUpperCase()}
            </Avatar>
          ) : (
            <Avatar
              alt="User picture"
              src={history.userBaseData.profilePic}
              sx={{ mr: 3, width: 56, height: 56 }}
            />
          )}
          <Box>
            <Typography component="div" variant="h6">
              {`${history.userBaseData.name} ${history.userBaseData.forename}`}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {history.userCredsData.mail}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {history.userCredsData.phone}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">
        {
          <Chip
            color="primary"
            icon={<PointOfSaleIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.paymentMode}
              </Typography>
            }
            sx={{
              marginBottom: "10px",
            }}
          />
        }
        <br />
        {
          <Chip
            color="primary"
            icon={<MonetizationOnIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.paymentAmount}
              </Typography>
            }
          />
        }
      </TableCell>
      <TableCell align="center">
        {
          <Chip
            color="primary"
            icon={<HomeIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.departureAddress}
              </Typography>
            }
            sx={{
              marginBottom: "10px",
            }}
          />
        }
        <br />
        {
          <Chip
            color="primary"
            icon={<LocationCityIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.departureDistrict}
              </Typography>
            }
          />
        }
      </TableCell>
      <TableCell align="center">
        {
          <Chip
            color="primary"
            icon={<HomeIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.arrivalAddress}
              </Typography>
            }
            sx={{
              marginBottom: "10px",
            }}
          />
        }
        <br />
        {
          <Chip
            color="primary"
            icon={<LocationCityIcon />}
            label={
              <Typography component="div" variant="h6">
                {history.arrivalDistrict}
              </Typography>
            }
          />
        }
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography color="#475569" variant="h5">
            {updated_at()}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const DriversHistoryTable = ({ processing, histories }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return histories && histories.length > 0 ? (
    <React.Fragment>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          sx={{
            minWidth: 600,
            borderCollapse: "separate",
            borderSpacing: "0 1rem",
          }}
        >
          <EnhancedTableHead rowCount={histories.length} />
          <TableBody>
            {histories
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((history, index) => (
                <HistoryRow
                  index={index}
                  key={history.id}
                  processing={processing}
                  history={history}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={histories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Entrées par pages"}
      />
    </React.Fragment>
  ) : histories && histories.length === 0 ? (
    <Empty title="Historique vide." />
  ) : (
    <LoadingTableView
      message="Chargement de l'historique"
      title="Patientez svp"
    />
  );
};

export default DriversHistoryTable;
