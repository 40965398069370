import { Card, CardContent, CardHeader } from "@mui/material";
import { useTheme } from "@mui/system";
import { useRetrieve } from "../hooks/useRetrieve";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const CommandsActivityWidget = () => {

  const { documents: bookings } = useRetrieve('users_car_booking');

  function bookingsPerMonth(month) {
    let amount = 0;
    bookings.forEach(element => {
      if (new Date(element.created_at["seconds"] * 1000 + element.created_at["nanoseconds"] / 1000000).getMonth() === month) {
        amount += 1;
      }
    });
    return amount;
  }
  function getMonthName(number) {
    let date = new Date();
    date.setMonth(number - 1);
    return date.toLocaleString([], {
      month: 'short',
    })
  }
  function createData() {
    let data = [];
    for (let index = 0; index <= 11; index++) {
      data[index] = {
        name: getMonthName(index + 1),
        pv: bookingsPerMonth(index),
      }
    }
    return data;
  }
  const data = createData();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title={
          "Activite annuelle des commandes [" + new Date().getFullYear() + "]"
        }
      />
      <CardContent>
        <ResponsiveContainer width="99%" height={244}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 16,
              left: 16,
              bottom: 5,
            }}
          >
            <XAxis
              axisLine={false}
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              tickLine={false}
              dataKey="name"
            />
            <Tooltip
              contentStyle={{
                borderRadius: 16,
                boxShadow: theme.shadows[3],
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
              }}
            />
            <Line
              name="Value"
              type="monotone"
              dataKey="pv"
              stroke={theme.palette.primary.main}
              strokeWidth={6}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export { CommandsActivityWidget };
