import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import Empty from "./Empty";
import * as selectUtils from "../utils/selectUtils";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LoadingTableView from "./LoadingView";
import { randomColor } from "../utils/randColorCode";

const headCells = [
  {
    id: "username",
    align: "left",
    label: "Plainte de",
  },
  {
    id: "objectName",
    align: "center",
    label: "Nom de l'objet",
  },
  {
    id: "complaintType",
    align: "center",
    label: "Type de déclaration",
  },
  {
    id: "objectPictures",
    align: "center",
    label: "Image(s) Soumise",
  },
  {
    id: "submittedAt",
    align: "center",
    label: "Date de soumission",
  },
];

function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
  return (
    <TableHead>
      <TableRow sx={{ "& th": { border: 0 } }}>
        <TableCell sx={{ py: 0 }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Select all objects",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} sx={{ py: 0 }}>
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="right" sx={{ py: 0 }}>
          {"Actions"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const ObjectRow = ({
  index,
  onCheck,
  onDelete,
  onView,
  processing,
  selected,
  object,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const updated_at = () => {
    if (
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getDay() === new Date().getDay() &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Aujourd'hui";
    } else if (
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 1 &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Hier";
    } else if (
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 2 &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Avant-hier";
    } else if (
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getDay() ===
        new Date().getDay() - 3 &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getMonth() === new Date().getMonth() &&
      new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      ).getFullYear() === new Date().getFullYear()
    ) {
      return "Il y a 3 jours";
    } else {
      return new Date(
        object.updated_at.seconds * 1000 +
          object.updated_at.nanoseconds / 1000000
      )
        .toLocaleString("fr-CM")
        .toString();
    }
  };

  const labelId = `enhanced-table-checkbox-${index}`;
  const openActions = Boolean(anchorEl);

  const handleOpenActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleCloseActions();
    onDelete([
      [object.id, object.userBaseData != null ? object.userBaseData.id : null],
    ]);
  };

  const handleView = () => {
    handleCloseActions();
    onView(object);
  };

  return (
    <TableRow
      aria-checked={selected}
      tabIndex={-1}
      key={object.id}
      selected={selected}
      sx={{ "& td": { bgcolor: "background.paper", border: 0 } }}
    >
      <TableCell
        padding="checkbox"
        sx={{ borderTopLeftRadius: "1rem", borderBottomLeftRadius: "1rem" }}
      >
        <Checkbox
          color="primary"
          checked={selected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
          onClick={() =>
            onCheck(
              object.id,
              object.userBaseData != null ? object.userBaseData.id : null
            )
          }
        />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {object.profilePic === "" ? (
            <Avatar
              sx={{
                mr: 3,
                width: 56,
                height: 56,
                backgroundColor: randomColor(),
                fontSize: 30,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {object.name != null && object.name !== ""
                ? object.userBaseData.name.toString().charAt(0).toUpperCase()
                : object.userBaseData.forename
                    .toString()
                    .charAt(0)
                    .toUpperCase()}
            </Avatar>
          ) : (
            <Avatar
              alt="User picture"
              src={object.userBaseData.profilePic}
              sx={{ mr: 3, width: 56, height: 56 }}
            />
          )}
          <Box>
            <Typography component="div" variant="h6">
              {`${object.userBaseData.name} ${object.userBaseData.forename}`}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {object.userCredsData.mail}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {object.userCredsData.phone}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {object.address}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Typography component="div" variant="h4">
          {`${object.objectName}`}
        </Typography>
        <br />
        <textarea
          readOnly
          contentEditable={false}
          style={{
            width: "15vw",
            height: "100px",
            borderRadius: "12px",
            fontSize: "20px",
          }}
        >{`${object.objectDescription}`}</textarea>
      </TableCell>
      <TableCell align="center">
        <Chip
          color={
            object.objectDeclarationType === "Perte" ? "warning" : "success"
          }
          label={`${
            object.objectDeclarationType === "Perte"
              ? "Objet perdu"
              : "Objet trouvé"
          }`}
          icon={
            object.objectDeclarationType === "Perte" ? (
              <AnnouncementIcon />
            ) : (
              <VolunteerActivismIcon />
            )
          }
        />
      </TableCell>
      <TableCell align="center">
        <ImageList
          sx={{ width: 400, height: 150 }}
          variant="quilted"
          cols={2}
          rowHeight={150}
        >
          {object.objectPictureOne !== "" && (
            <ImageListItem key={"0"} cols={1} rows={1}>
              <img
                src={object.objectPictureOne}
                alt={"Proof 1"}
                loading="eager"
                style={{
                  borderRadius: "12px",
                }}
              />
            </ImageListItem>
          )}
          {object.objectPictureTwo !== "" && (
            <ImageListItem key={"1"} cols={1} rows={1}>
              <img
                src={object.objectPictureTwo}
                alt={"Proof 2"}
                loading="eager"
                style={{
                  borderRadius: "12px",
                }}
              />
            </ImageListItem>
          )}
        </ImageList>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography color="textSecondary" variant="h5">
            {updated_at()}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        align="right"
        sx={{ borderTopRightRadius: "1rem", borderBottomRightRadius: "1rem" }}
      >
        <IconButton
          id="object-row-menu-button"
          aria-label="object actions"
          aria-controls="object-row-menu"
          aria-haspopup="true"
          aria-expanded={openActions ? "true" : "false"}
          disabled={processing}
          onClick={handleOpenActions}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="object-row-menu"
          anchorEl={anchorEl}
          aria-labelledby="object-row-menu-button"
          open={openActions}
          onClose={handleCloseActions}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleView}>
            <ListItemIcon>
              <PersonSearchIcon />
            </ListItemIcon>{" "}
            {"Consulter"}
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>{" "}
            {"Supprimer"}
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

const ObjectsTable = ({
  onDelete,
  onView,
  onSelectedChange,
  processing,
  selected,
  objects,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = selectUtils.selectAllDrivers(objects);
      // console.log("ALL IDS SELECTED ", newSelectedIds);
      onSelectedChange(newSelectedIds);
      return;
    }
    onSelectedChange([]);
  };

  const handleClick = (object_id, base_id) => {
    let newSelected = selectUtils.selectOne(selected, object_id, base_id);
    onSelectedChange(newSelected);
    //console.log("UPDATED SELECTED IDS ", newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function isInArr(array2d, item) {
    return (
      [].concat.apply([], [].concat.apply([], array2d)).indexOf(item) !== -1
    );
  }

  const isSelected = (id) => isInArr(selected, id);

  return objects && objects.length > 0 ? (
    <React.Fragment>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          sx={{
            minWidth: 600,
            borderCollapse: "separate",
            borderSpacing: "0 1rem",
          }}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={objects.length}
          />
          <TableBody>
            {objects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((object, index) => (
                <ObjectRow
                  index={index}
                  key={object.id}
                  onCheck={handleClick}
                  onDelete={onDelete}
                  onView={onView}
                  processing={processing}
                  selected={isSelected(object.id)}
                  object={object}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={objects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Entrées par pages"}
      />
    </React.Fragment>
  ) : objects && objects.length === 0 ? (
    <Empty title="Pas encore d'objet déclaré..." />
  ) : (
    <LoadingTableView
      message="Chargement des objets..."
      title="Patientez svp"
    />
  );
};

export default ObjectsTable;
