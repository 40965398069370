import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useRetrieve } from "../hooks/useRetrieve";

import { Bar, BarChart, ResponsiveContainer } from "recharts";

const SalesWidget = () => {
  const { documents: bookings } = useRetrieve('users_car_booking');
  const theme = useTheme();
  // i use this to contourn password payload who doesn't work
  // <Route path="/tmc" element={<Home isLoaded={isLoaded} setIsLoaded={setIsLoaded} />} />
  function bookOfTheDay(d) {
    let amount = 0;
    bookings.forEach(element => {
      if (new Date(element.created_at["seconds"] * 1000 + element.created_at["nanoseconds"] / 1000000).getDate() === d.getDate()) {
        amount += 1;
      }
    });
    return amount;
  }

  function getDayName(day) {
    let days = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
    let name = "null";
    for (let i = 0; i < days.length; i++) {
      if (day === days.indexOf(days[i])) {
        name = days[i];
      }
    }
    return name;
  }

  function fromNumberToDateWeek(number) {
    let weekDays = [];
    let currentDate = new Date();
    let finalDate = new Date();
    //let firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));
    //let lastDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7));
    for (let i = 0; i <= 6; i++) {
      weekDays[i] = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + i + 1));
    }
    for (let j = 0; j < weekDays.length; j++) {
      if (number === weekDays[j].getDay()) {
        finalDate = new Date(finalDate.setDate((weekDays[j].getDate())));
      }
    }
    return finalDate;
  }

  function createData() {
    let data = [];
    for (let index = 0; index <= 6; index++) {
      data[index] = {
        name: getDayName(index).slice(0,3),
        uv: bookOfTheDay(fromNumberToDateWeek(index)),
      }
    }
    return data;
  }

  const data = createData();

  return (
    <Card>
      <CardHeader title={"Historique des ventes (Hebdomadaire)"} />
      <CardContent>
        <ResponsiveContainer width="99%" height={124}>
          <BarChart
            width={150}
            height={40}
            data={data}
            margin={{
              right: 0,
              left: 0,
            }}
          >
            <Bar
              dataKey="uv"
              fill={theme.palette.primary.main}
              radius={[50, 50, 50, 50]}
            />
          </BarChart>
        </ResponsiveContainer>
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h2" component="div" marginBottom={1}>
              {bookOfTheDay(new Date())}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              {"CDF Aujourd'hui"}
            </Typography>
          </Box>
          <TrendingUpIcon sx={{ color: "text.secondary" }} />
        </Box>
      </CardContent>
    </Card>
  );
};

export { SalesWidget };
