import { CardContent, Card, Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useRetrieve } from "../hooks/useRetrieve";
import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const UsersRegistrationActivityWidget = () => {
  const { documents : users } = useRetrieve('users_accnt');

  function userPerMonth(month) {
    let amount = 0;
    users.forEach(element => {
      if (new Date(element.created_at["seconds"] * 1000 + element.created_at["nanoseconds"] / 1000000).getMonth() === month) {
        amount += 1;
      }
    });
    return amount;
  }

  function getMonthName(number) {
    let date = new Date();
    date.setMonth(number - 1);
    return date.toLocaleString([], {
      month: 'short',
    })
  }
  // this function is for later
  // when it will be possible to make statistics between 2 years
  // this will help us to create dynamically data for the chart card :)
  /*function someDatas() {
    let datas = [];
    for (var i = 0; i < 2; i++) {
      datas[i] = {
        year: "2023",
        months: [],
      }
      for(let j = 0; j <=11; j++) {
        datas[i].months[j] = {
          name: getMonthName(j + 1),
          amount: userPerMonth(j + 1),
        }
      }
    }
    return datas;
  }
  const datas = someDatas();
  console.log(datas);
  */

  function createData() {
    let data = [];
    for (let index = 0; index <= 11; index++) {
      data[index] = {
        name: getMonthName(index + 1),
        amount: userPerMonth(index + 1),
      }
    }
    return data;
  }

  const currentDateMonth = new Date().getMonth();
  const data = createData();
  const totalNewUsers = users ? Object.keys(users).length : "40";
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Typography
          align="center"
          component="div"
          marginBottom={0}
          variant="body2"
        >
          {"Nouveaux utilisateurs"}
        </Typography>
        <Typography
          align="center"
          component="div"
          variant="h2"
          color={theme.palette.text.primary}
          marginBottom={3}
        >
          {totalNewUsers}
        </Typography>
        <Box sx={{ height: 224 }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data.slice(currentDateMonth-1,currentDateMonth+4)}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis
                axisLine={false}
                dataKey="name"
                interval="preserveStartEnd"
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                tickLine={false}
              />
              <Tooltip
                contentStyle={{
                  borderRadius: 16,
                  boxShadow: theme.shadows[3],
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.background.paper,
                }}
              />
              <Area
                type="monotone"
                dataKey="amount"
                fill={theme.palette.primary.main}
                fillOpacity={0.3}
                stroke={theme.palette.primary.main}
                strokeWidth={6}
                activeDot={{ r: 8 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export { UsersRegistrationActivityWidget };
