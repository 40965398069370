import React, { useState, useEffect } from "react";
import AdminToolbar from "../AdminToolbar";
import ConfirmDialog from "../ConfirmDialog";
import SelectToolbar from "../SelectToolbar";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { deleteObjects } from "../../hooks/deleteObjects";
import { fetchObjects } from "../../hooks/getObjects";
import ObjectsTable from "../ObjectsTable";
import ObjectDialog from "../ObjectsDialog";

const FoundLost = (props) => {
  const snackbar = useSnackbar();

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [objectsToDelete, setObjectsToDelete] = useState([]);
  const [objectUpdated, setObjectUpdated] = useState(undefined);
  const [objects, setObjects] = useState();
  const [isOperating, setIsOperating] = useState(props.isLoaded);

  useEffect(() => {
    props.setIsLoaded(false);
    setObjects();
    handleGetObjects();
  }, []);

  useEffect(() => {
    //console.log("OBJECTS TO DELETE IDS ", objectsToDelete, objectsToDelete.length);
  }, [objectsToDelete]);

  const setObjectsToBeDeleted = (ids) => {
    setObjectsToDelete((prevState) => [...prevState, ...ids]);
  };

  const processing = !props.isLoaded || isOperating;

  const handleGetObjects = async () => {
    fetchObjects().then((res) => {
      setObjects(res);
      props.setIsLoaded(true);
      setIsOperating(false);
    });
  };

  const handleDeleteObjects = async () => {
    setIsOperating(true);
    // console.log(
    //   "OBJECTS TO DELETE PASSED ",
    //   objectsToDelete,
    //   objectsToDelete.length
    // );
    deleteObjects(objectsToDelete)
      .then((result) => {
        if (result === true) {
          snackbar.success("L'objet a bien ete supprime");
          setSelected([]);
          setOpenConfirmDeleteDialog(false);
          setIsOperating(false);
          setObjectsToDelete([]);
          setObjects();
          handleGetObjects();
        } else {
          snackbar.error("Une erreur est survenue a la suppression");
          setIsOperating(false);
        }
      })
      .catch(() => {
        snackbar.error("Une erreur est survenue a la suppression");
        setIsOperating(false);
      });
  };

  const handleViewObject = async (object) => {};

  const handleCancelSelected = () => {
    setSelected([]);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
    setSelected([]);
    setObjectsToDelete([]);
  };

  const handleCloseUserDialog = () => {
    setObjectUpdated(undefined);
    setOpenObjectDialog(false);
  };

  const handleOpenConfirmDeleteDialog = (ObjectsIds) => {
    setObjectsToBeDeleted(ObjectsIds);
    setOpenConfirmDeleteDialog(true);
  };

  const handleOpenObjectDialog = (object) => {
    setObjectUpdated(object);
    setOpenObjectDialog(true);
  };

  const handleSelectedChange = (newSelected) => {
    setSelected(newSelected);
  };

  return (
    <React.Fragment>
      {!selected.length ? (
        <AdminToolbar title={"Objets trouvés et perdus"}></AdminToolbar>
      ) : (
        <SelectToolbar
          processing={processing}
          onCancel={handleCancelSelected}
          onDelete={handleOpenConfirmDeleteDialog}
          selected={selected}
        />
      )}
      <ObjectsTable
        processing={processing}
        onDelete={handleOpenConfirmDeleteDialog}
        onView={handleOpenObjectDialog}
        onSelectedChange={handleSelectedChange}
        selected={selected}
        objects={objects}
      />
      <ConfirmDialog
        description={"Cette action sera irreversible !"}
        pending={processing}
        onClose={handleCloseConfirmDeleteDialog}
        onConfirm={handleDeleteObjects}
        open={openConfirmDeleteDialog}
        title={"Confirmez vous la suppression ?"}
      />
      {openObjectDialog && (
        <ObjectDialog
          onAdd={() => {}}
          onClose={handleCloseUserDialog}
          onView={handleViewObject}
          open={openObjectDialog}
          processing={processing}
          object={objectUpdated}
        />
      )}
    </React.Fragment>
  );
};

export default FoundLost;
