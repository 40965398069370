import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DriversHistoryTable from "./DriversHistoryTable";

const DriverHistoryDialog = ({ onClose, open, loading, driver, history }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="fueling-export-dialog-title"
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle id="fueling-dialog-title">
        {`Historique des courses de ${driver.name} ${driver.forename}`}
      </DialogTitle>
      <DialogContent sx={{ height: "500px" }}>
        <DriversHistoryTable histories={history} processing={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{"OK"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DriverHistoryDialog;
