import { useState,useEffect } from "react";
import { db } from "../configs/firebase";
import { collection,onSnapshot } from "firebase/firestore";

export const useRetrieve = (c) => {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        let q = collection(db, c);

        const onsub = onSnapshot(q, (snapshot) => {
            let results = [];
            snapshot.docs.forEach(doc =>{
                results.push({id: doc.id, ...doc.data()})
            })
            setDocuments(results);
        })

        return() => onsub();
    }, [c])

    return { documents }
}