import {
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../configs/firebase";

export const fetchDriverHistory = async (driver) => {
  try {
    const usersCarBookingDataRef = collection(
      db,
      `${process.env.REACT_APP_USERS_CAR_BOOKING_COLLECTION_NAME}`
    );
    let driverUser = doc(
      db,
      process.env.REACT_APP_USERS_BASE_COLLECTION_NAME,
      driver.UID._key.path.segments[6]
    );
    let driverUserRef = await getDoc(driverUser).then((querySnapshot) => {
      return querySnapshot.ref;
    });
    const getUsersBookingsByDriverIDQuery = await query(
      usersCarBookingDataRef,
      where("approvedByUID", "==", driverUserRef),
      orderBy("updated_at", "desc")
    );
    const historyData = await getDocs(getUsersBookingsByDriverIDQuery).then(
      async (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        for (let x = 0; x < data.length; x++) {
          let docRef = doc(
            db,
            process.env.REACT_APP_USERS_BASE_COLLECTION_NAME,
            data[x].UID._key.path.segments[6]
          );
          let baseDataRef;
          const userBaseData = await getDoc(docRef).then((querySnapshot) => {
            baseDataRef = querySnapshot.ref;
            const user = {
              id: querySnapshot.id,
              ...querySnapshot.data(),
            };
            return user;
          });
          const userCredsData = await getDocs(
            query(
              collection(
                db,
                `${process.env.REACT_APP_USERS_CREDS_COLLECTION_NAME}`
              ),
              where("UID", "==", baseDataRef)
            )
          ).then((querySnapshot) => {
            const userCreds = {
              id: querySnapshot.docs[0].id,
              ...querySnapshot.docs[0].data(),
            };
            return userCreds;
          });
          data[x].userBaseData = userBaseData;
          data[x].userCredsData = userCredsData;
        }

        return data;
      }
    );

    // console.log("HSITORY DATA", historyData);
    return historyData;
  } catch (error) {
    // console.log("AN ERROR OCCURED WHILE GETTING HIST ", error);
    return [];
  }
};
