import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { randomColor } from "../utils/randColorCode";

const ObjectDialog = ({
  onAdd,
  onClose,
  onView,
  open,
  processing,
  object,
  index,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => (event, isExpanded) => {
    expanded ? setExpanded(false) : setExpanded(true);
  };

  const formik = useFormik({
    initialValues: {
      name: object ? object.name : "",
      forename: object ? object.forename : "",
      address: object ? object.address : "",
      phone: object ? object.phone : "",
      objectName: object ? object.objectName : "",
      objectDescription: object ? object.objectDescription : "",
      objectPictureOne: object ? object.objectPictureOne : "",
      objectPictureTwo: object ? object.objectPictureTwo : "",
      objectDeclarationType: object ? object.objectDeclarationType : "",
      detailsFoundPoint: object ? object.detailsFoundPoint : "",
      detailsFoundTime: object ? object.detailsFoundTime : "",
      detailsLostPoint: object ? object.detailsLostPoint : "",
      detailsLostTime: object ? object.detailsLostTime : "",
      detailsVehicleID: object ? object.detailsVehicleID : "",
      accountMail:
        object && object.userCredsData ? object.userCredsData.mail : "",
      accountName: object
        ? object.userBaseData && object.userBaseData.name
        : "",
      accountForename: object
        ? object.userBaseData && object.userBaseData.forename
        : "",
      accountPhone: object
        ? object.userCredsData && object.userCredsData.phone
        : "",
    },
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="object-dialog-title">
      <DialogTitle id="object-dialog-title">
        {"Consulter la declaration"}
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={3}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={"Nom de l'auteur"}
            name="name"
            disabled={true}
            value={formik.values.name}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="forename"
            label={"Prénom de l'auteur"}
            name="forename"
            disabled={true}
            value={formik.values.forename}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="address"
            label={"Adresse de l'auteur"}
            name="address"
            disabled={true}
            value={formik.values.address}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label={"Telephone de l'auteur"}
            name="driverPermitNumber"
            disabled={true}
            value={formik.values.phone}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="objectName"
            label={"Nom de l'objet"}
            name="objectName"
            disabled={true}
            value={formik.values.objectName}
          />
          <textarea
            readOnly
            contentEditable={false}
            style={{
              width: "100%",
              borderRadius: "20px",
              borderWidth: "1px",
              borderColor: "gray",
              color: "gray",
              fontSize: "20px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            {formik.values.objectDescription}
          </textarea>

          <ImageList
            sx={{ width: "100%", height: 250 }}
            variant="quilted"
            cols={2}
            rowHeight={250}
          >
            {object.objectPictureOne !== "" && (
              <ImageListItem key={"0"} cols={1} rows={1}>
                <img
                  src={formik.values.objectPictureOne}
                  alt={"Proof 1"}
                  loading="eager"
                  style={{
                    borderRadius: "12px",
                  }}
                />
              </ImageListItem>
            )}
            {object.objectPictureTwo !== "" && (
              <ImageListItem key={"1"} cols={1} rows={1}>
                <img
                  src={formik.values.objectPictureTwo}
                  alt={"Proof 2"}
                  loading="eager"
                  style={{
                    borderRadius: "12px",
                  }}
                />
              </ImageListItem>
            )}
          </ImageList>

          <TextField
            margin="normal"
            required
            fullWidth
            id="objectDeclarationType"
            label={"Type de declaration"}
            name="objectDeclarationType"
            disabled={true}
            value={formik.values.objectDeclarationType}
          />

          {formik.values.objectDeclarationType === "Perte" ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="detailsLostPoint"
                label={"Lieu de perte"}
                name="detailsLostPoint"
                disabled={true}
                value={formik.values.detailsLostPoint}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="detailsLostTime"
                label={"Horaire de perte"}
                name="detailsLostTime"
                disabled={true}
                value={new Date(formik.values.detailsLostTime)
                  .toLocaleString("fr-CG")
                  .toString()}
              />
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="detailsFoundPoint"
                label={"Lieu de trouvaille"}
                name="detailsFoundPoint"
                disabled={true}
                value={formik.values.detailsFoundPoint}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="detailsFoundTime"
                label={"Horaire de trouvaille"}
                name="detailsFoundTime"
                disabled={true}
                value={new Date(formik.values.detailsFoundTime)
                  .toLocaleString("fr-CG")
                  .toString()}
              />
            </>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="created_at"
            label={"Date de la declaration"}
            name="created_at"
            disabled={true}
            value={new Date(
              object.created_at.seconds * 1000 +
                object.created_at.nanoseconds / 1000000
            )
              .toLocaleString("fr-CG")
              .toString()}
          />
        </Box>
        {object.userBaseData !== null && object.userCredsData !== null ? (
          <>
            <Divider variant="middle" />
            <Box marginTop={3}>
              <Accordion
                expanded={expanded}
                onChange={handleExpand()}
                sx={{ backgroundColor: "#e8fff5" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography
                    sx={{ width: "100%", flexShrink: 0 }}
                    variant="h6"
                  >
                    {"Informations supplémentaires"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    padding={2}
                    display={"inline-flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      {object.userBaseData.profilePic === "" ? (
                        <Avatar
                          sx={{
                            backgroundColor: randomColor(),
                            fontSize: 50,
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                          }}
                          style={{
                            margin: "10px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          {object.userBaseData.forename != null &&
                          object.userBaseData.name !== ""
                            ? object.userBaseData.name
                                .toString()
                                .charAt(0)
                                .toUpperCase()
                            : object.userBaseData.forename
                                .toString()
                                .charAt(0)
                                .toUpperCase()}
                        </Avatar>
                      ) : (
                        <Avatar
                          src={object.userBaseData.profilePic}
                          alt="User picture"
                          style={{
                            margin: "10px",
                            width: "120px",
                            height: "120px",
                          }}
                        />
                      )}
                      <Typography
                        align="center"
                        component="div"
                        marginBottom={0}
                        variant="body2"
                      >
                        {"Photo de profil"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="accountName"
                      label={"Nom (de compte)"}
                      name="accountName"
                      disabled={true}
                      value={formik.values.accountName}
                    />
                  }
                  {
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="accountForename"
                      label={"Prénom (de compte)"}
                      name="accountForename"
                      disabled={true}
                      value={formik.values.accountForename}
                    />
                  }
                  {
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="accountPhone"
                      label={"Numéro de téléphone (de compte)"}
                      name="accountPhone"
                      disabled={true}
                      value={formik.values.accountPhone}
                    />
                  }
                </AccordionDetails>
              </Accordion>
            </Box>
          </>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{"OK"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectDialog;
